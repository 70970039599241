import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';

export const HANDOUT_ENTITY_LIST_FRAGMENT = `
  fragment handoutEntityListFragment on Handout {
    id
    uid
    __typename
    schemaCode
    name
    fileUrl
    handoutFileResource {
      ...fileResourceBaseFragment
    }
    relatedEntity: _relatedEntity {
      entityType: __typename
      uid
    }
  }
  ${FILE_RESOURCE_BASE_FRAGMENT}
`;
